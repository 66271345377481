import axios from "axios";
import vueThis from "../main.js";
var baseURL = "";
var uniBaseUrl = "";
if (process.env.NODE_ENV == "development") {
  // 开发
  uniBaseUrl = "https://api.difengsoft.com/store-dev";
  baseURL = "http://dsk-uat.difengsoft.com";
} else if (process.env.NODE_ENV == "production") {
  // 生产
  uniBaseUrl = "https://store.difengsoft.com";
  baseURL = "https://dsk.difengsoft.com";
}

// 通用公用方法
const request = (
  method,
  url,
  params = {},
  json = true,
  toastErrmsg = true,
  switchURl = false,
  isRxcode = false,
  timeout = 30000
) => {
  let token = window.sessionStorage.getItem("token");
  let selectStoreGuid = window.sessionStorage.getItem("selectStoreGuid");
  let contentType = "application/x-www-form-urlencoded";
  // 适配get
  let reGet = /get{0,3}$/gi;
  let rePost = /post{0,4}$/gi;

  if (reGet.test(method)) {
    url += url.indexOf('?') == -1 ? "?" : '&';
    for (let key in params) {
      url += `${key}=${params[key]}&`;
    }
    url = url.slice(0, -1);
  }
  if (json) {
    contentType = "application/json; charset=utf8;";
  }

  if (isRxcode) {
    url =
      url +
      (url.indexOf("?") > -1 ? "&" : "?") +
      `rxcode=${window.localStorage.getItem("rxcode")}`;
  }

  return new Promise((resolve, reject) => {
    axios({
      method: method,
      baseURL: switchURl ? uniBaseUrl : baseURL,
      url: url,
      headers: {
        "Content-Type": contentType,
        acctoken: token,
        m_stores_guid: selectStoreGuid,
      },
      data: params,
      traditional: true,
      timeout: timeout,
    }).then((res) => {
      if (
        res.data.errcode == 601 ||
        res.data.errcode == 602 ||
        res.data.errcode == 603
      ) {
        let text =
          res.data.errcode == 602
            ? "当前账号已被其他设备登录"
            : "登录已过期，请重新登录";
        if (res.data.errcode == 602 || res.data.errcode == 603) {
          vueThis.logout();
          vueThis.$message.error(text);
        }
      } else if (res.data.errcode == 610) {
        let callback = () => {
          vueThis
            .$confirm("操作超时，请重新操作", "提示", {
              cancelButtonText: "取消",
            })
            .finally(() => {
              reject(res);
            });
        };
        vueThis.$store.dispatch('initRxcode', callback);
      } else if (res.data.errcode == 0 || res.data.errcode > 1) {
        resolve(res.data);
        if (isRxcode && res.data.errcode > 1) {
          vueThis.$store.dispatch('initRxcode');
        }
      } else {
        if (toastErrmsg) {
          vueThis.$message.error(res.data.errmsg || "操作失败");
        }
        if (isRxcode) {
          vueThis.$store.dispatch('initRxcode');
        }
        reject(res);
      }
    }).catch((err)=>{
		console.log(err, 'ssss');
	});
  });
};

export { request, baseURL };
